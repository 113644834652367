import * as React from "react"
import styled from 'styled-components';
import { Link } from "gatsby"
//Components


const NewLayout = () => {
  return (
    <Grid>

<nav> 
      <div>
        <StyledLink>Home</StyledLink>
        <StyledLink>About</StyledLink>
        <StyledLink>Resume</StyledLink>
      </div>
        
        
      </nav>

    <section className="portfolio-showcase">
      <div className="portfolio-card">
        <h4 className="card-title">Buoy</h4>
        <p className="card-text">I can’t do that as Bruce Wayne… as a man. I’m flesh and blood. I can be ignored, destroyed. But as a symbol, I can be incorruptible, I can be everlasting.</p>
      </div>
      <div className="portfolio-card">
        <h4 className="card-title">Bootstrap Design</h4>
        <p className="card-text">I can’t do that as Bruce Wayne… as a man. I’m flesh and blood. I can be ignored, destroyed. But as a symbol, I can be incorruptible, I can be everlasting.</p>
      </div>
      <div className="portfolio-card">
        <h4 className="card-title">Candor</h4>
        <p className="card-text">I can’t do that as Bruce Wayne… as a man. I’m flesh and blood. I can be ignored, destroyed. But as a symbol, I can be incorruptible, I can be everlasting.</p>
      </div>
      <div className="portfolio-card">
        <h4 className="card-title">Underwriters Labratory</h4>
        <p className="card-text">I can’t do that as Bruce Wayne… as a man. I’m flesh and blood. I can be ignored, destroyed. But as a symbol, I can be incorruptible, I can be everlasting.</p>
      </div>
      <div className="portfolio-card">
        <h4 className="card-title">Echo Ship</h4>
        <p className="card-text">I can’t do that as Bruce Wayne… as a man. I’m flesh and blood. I can be ignored, destroyed. But as a symbol, I can be incorruptible, I can be everlasting.</p>
      </div>
      <div className="portfolio-card">
        <h4 className="card-title">Point of Care</h4>
        <p className="card-text">I can’t do that as Bruce Wayne… as a man. I’m flesh and blood. I can be ignored, destroyed. But as a symbol, I can be incorruptible, I can be everlasting.</p>
      </div>
      <div className="portfolio-card">
        <h4 className="card-title">Caremerge Family App</h4>
        <p className="card-text">I can’t do that as Bruce Wayne… as a man. I’m flesh and blood. I can be ignored, destroyed. But as a symbol, I can be incorruptible, I can be everlasting.</p>
      </div>
      <div className="portfolio-card">
        <h4 className="card-title">Calendar Central</h4>
        <p className="card-text">I can’t do that as Bruce Wayne… as a man. I’m flesh and blood. I can be ignored, destroyed. But as a symbol, I can be incorruptible, I can be everlasting.</p>
      </div>
    </section>
    </Grid>
  )
}

export default NewLayout




const Grid = styled.div`
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  max-width: 1110px;
  margin: 0 auto;

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 2%;
    border-radius: 8px;

    background-color: ${props => props.theme.mode === 'dark' ? '#333333' : '#F8F9FA'};
  }

  .portfolio-showcase {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    padding: 2% 2%;
    margin: 0 auto;
  }
  
  .portfolio-showcase .portfolio-card {
    height: 20rem;
    width: 20rem;
    background-color: #F0F0F0;
    border-radius: 16px;
    margin: 2% 0%;
    transition: all 300ms ease;
    /* box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.030); */
  }
  
  .portfolio-card .card-title {
    padding: 6% 6%;
    font-size: 18px;
    font-weight: bold;
    color: #0072ff;
  }
  
  .portfolio-card .card-text {
    padding: 6% 6%;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
  }
  
  .portfolio-showcase .portfolio-card:hover {
    opacity: .8;
    cursor: pointer;
    transform: scale(1.01);
    transition: all 300ms ease;
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  color: rgba(0, 0, 0, 0.9);
  font-size: 16px;
  padding-right: 16px;

  color: ${props => props.theme.mode === 'dark' ? 'rgba(255, 255, 255, 0.9);' : 'rgba(0, 0, 0, 0.9)'};
`;




